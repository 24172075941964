import { ScannerType } from "@/pages/scanner-control/config";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { saveAs } from "file-saver";
import { usePipeScannersStore } from "./usePipeScannersStore";
import { unitsConverter } from "@/config/units";
import { filterScanners } from "@/features/pipescanner/helpers/filterScanners";
import { getMinMaxValue } from "@/utils/filterHelpers";
import { auth0 } from "@/config/auth0";
import { getMultipleScannersDetails } from "@/features/pipescanner/api";
import { useAuthStore } from "./useAuthStore";
import ExportXlsx from "@/utils/ExportXlsx";
import { useSettingsStore } from "./useSettingsStore";
import { mergeChanges } from "@/utils/objects";
import type {
  ScannerFilters,
  ScannerIds,
  ScannersExportSettings,
  ScannersService,
} from "@/types/pipeScanners";
import { showToastError } from "@/utils/errors";

const requests = new Map();

const INITIAL_SCANNER_FILTERS: ScannerFilters = {
  search: "",
  inspectionDateRange: [
    "2020-01-01",
    dayjs().add(1, "day").format("YYYY-MM-DD"),
  ],
  materials: [],
  diameterRange: [-Infinity, Infinity],
  constuctionYearRange: [String(1900), String(new Date().getFullYear())],
};

export const useScannerControlStore = defineStore("scannerControl", () => {
  const pipeScannersStore = usePipeScannersStore();
  const authStore = useAuthStore();
  const settingsStore = useSettingsStore();

  const filters = ref(INITIAL_SCANNER_FILTERS);

  function setFilters(changes: Partial<ScannerFilters>) {
    mergeChanges(filters.value, changes);
    selectedResultScannerIds.value = [];
  }
  function initFilters() {
    setFilters({ diameterRange: minMaxDiameters.value });
  }

  const selectedScannerType = ref(ScannerType.Results);

  function setSelectedScannerType(newType) {
    selectedScannerType.value = newType;
  }

  const selectedResultScannerIds = ref<string[]>([]);

  const selectedResultsScannersCount = computed(
    () => selectedResultScannerIds.value.length
  );

  function addSelectedResultScannerId(newId: string) {
    selectedResultScannerIds.value.push(newId);
  }
  function removeSelectedResultScannerId(idToRemove: string) {
    selectedResultScannerIds.value = selectedResultScannerIds.value.filter(
      (id) => id !== idToRemove
    );
  }
  function clearSelectedResultScannerIds() {
    selectedResultScannerIds.value = [];
  }
  function setSelectedResultScannerIds(ids: string[]) {
    selectedResultScannerIds.value = ids;
  }
  function toggleAllResultsScanners() {
    if (isAllResultsScannersSelected.value) {
      clearSelectedResultScannerIds();
      return;
    }

    setSelectedResultScannerIds(filteredResultsScannersIds.value);
  }
  function toggleResultsScannerSelection(id: string) {
    if (selectedResultScannerIds.value.includes(id)) {
      removeSelectedResultScannerId(id);
    } else {
      addSelectedResultScannerId(id);
    }
  }

  const isAwaitingInspectionExport = ref(false);

  function setIsAwaitingInspectionExport(isAwaitingExport: boolean) {
    isAwaitingInspectionExport.value = isAwaitingExport;
  }
  async function exportResultsScanners() {
    try {
      setIsAwaitingInspectionExport(true);

      const inspections = await fetchScannersDetails();

      const settings = {
        ...exportSettings.value,
        language: settingsStore.locale,
      };

      const excelExport = new ExportXlsx(
        inspections,
        0.1,
        15.0,
        settings,
        authStore.selectedGroup,
        settingsStore.units
      );

      const export_wb = await excelExport.generate_export_prom();
      const buffer = await export_wb.writeToBuffer();
      const file = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(file, "Export-" + dayjs().toISOString() + ".xlsx");
    } catch (error) {
      showToastError(error);
    } finally {
      setIsAwaitingInspectionExport(false);
    }
  }

  const activeScannerIds = ref<ScannerIds>({
    [ScannerType.Results]: null,
    [ScannerType.InfoPending]: null,
    [ScannerType.AnalysisPending]: null,
  });

  const activeScannerId = computed(
    () => activeScannerIds.value[selectedScannerType.value]
  );
  const activeScanner = computed(() =>
    pipeScannersStore.pipeScanners[selectedScannerType.value].find(
      (scanner) => scanner.id === activeScannerId.value
    )
  );

  function setActiveScannerIds(changes: Partial<ScannerIds>) {
    mergeChanges(activeScannerIds.value, changes);
  }
  function toggleActiveScannerId(id: string) {
    if (activeScannerIds.value[selectedScannerType.value] === id) {
      setActiveScannerIds({ [selectedScannerType.value]: null });
      return;
    }

    setActiveScannerIds({ [selectedScannerType.value]: id });
  }

  const exportSettings = ref<ScannersExportSettings | null>(null);

  function setExportSettings(settings: ScannersExportSettings) {
    exportSettings.value = settings;
  }

  const scannersService = ref<ScannersService | null>(null);

  function setScannersService(service: ScannersService) {
    scannersService.value = service;
  }

  const convertedResultsScanners = computed(() => {
    const scanners = pipeScannersStore.pipeScanners[ScannerType.Results];

    if (settingsStore.units === "metric") {
      return scanners;
    }

    const convertedScanners = scanners.map((scanner) => {
      ["diameter", "wallthickness_expected_validation"].forEach((key) => {
        scanner[key] = unitsConverter.instance.convert(scanner[key], "mm");
      });

      ["max", "mean", "min", "std"].forEach((key) => {
        scanner.stats[key] = unitsConverter.instance.convert(
          scanner.stats[key],
          "mm"
        );
      });

      return scanner;
    });

    return convertedScanners;
  });
  const minMaxDiameters = computed<[number, number]>(() => {
    const [min, max] = getMinMaxValue(
      convertedResultsScanners.value,
      "diameter"
    );

    return [Math.floor(min), Math.ceil(max)];
  });
  const filteredResultsScanners = computed(() => {
    const filtered = filterScanners(
      convertedResultsScanners.value,
      ScannerType.Results,
      filters.value
    );

    return filtered;
  });
  const filteredResultsScannersIds = computed(() =>
    filteredResultsScanners.value.map((scanner) => scanner.id)
  );
  const filteredInfoPendingScanners = computed(() => {
    return filterScanners(
      pipeScannersStore.pipeScanners[ScannerType.InfoPending],
      ScannerType.InfoPending,
      filters.value
    );
  });
  const filteredAnalysisPendingScanners = computed(() => {
    return filterScanners(
      pipeScannersStore.pipeScanners[ScannerType.AnalysisPending],
      ScannerType.AnalysisPending,
      filters.value
    );
  });
  const filteredPipeScanners = computed(() => {
    return {
      [ScannerType.Results]: filteredResultsScanners.value,
      [ScannerType.InfoPending]: filteredInfoPendingScanners.value,
      [ScannerType.AnalysisPending]: filteredAnalysisPendingScanners.value,
    };
  });
  const currentFilteredScanners = computed(
    () => filteredPipeScanners.value[selectedScannerType.value]
  );
  const hasFilteredScanners = computed(() => {
    const hasFilteredResultsScanners =
      filteredResultsScanners.value.length !==
      pipeScannersStore.pipeScanners[ScannerType.Results].length;

    const hasFilteredInfoPendingScanners =
      filteredInfoPendingScanners.value.length !==
      pipeScannersStore.pipeScanners[ScannerType.InfoPending].length;

    const hasFilteredAnalysisPendingScanners =
      filteredAnalysisPendingScanners.value.length !==
      pipeScannersStore.pipeScanners[ScannerType.AnalysisPending].length;

    const filtered = {
      [ScannerType.Results]: hasFilteredResultsScanners,
      [ScannerType.InfoPending]: hasFilteredInfoPendingScanners,
      [ScannerType.AnalysisPending]: hasFilteredAnalysisPendingScanners,
    };

    return filtered[selectedScannerType.value];
  });
  const isAllResultsScannersSelected = computed(
    () =>
      filteredResultsScanners.value.length > 0 &&
      selectedResultsScannersCount.value ===
        filteredResultsScanners.value.length
  );

  async function fetchPipeScanners() {
    const requestsToFetch: Promise<any>[] = [];

    if (pipeScannersStore.pipeScanners[ScannerType.Results].length === 0) {
      const fetchResultsScanners = pipeScannersStore.fetchResultsScanners();
      requestsToFetch.push(fetchResultsScanners);
    }

    if (pipeScannersStore.pipeScanners[ScannerType.InfoPending].length === 0) {
      const fetchInfoScanners = pipeScannersStore.fetchInfoScanners();
      requestsToFetch.push(fetchInfoScanners);
    }

    if (
      pipeScannersStore.pipeScanners[ScannerType.AnalysisPending].length === 0
    ) {
      const fetchAnalysisPendingScanners =
        pipeScannersStore.fetchAnalysisPendingScanners();
      requestsToFetch.push(fetchAnalysisPendingScanners);
    }

    await Promise.all(requestsToFetch);
    initFilters();
  }

  async function fetchScannersDetails() {
    try {
      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("multipleScannersDetails", controller);

      const inspection = await getMultipleScannersDetails(
        token,
        authStore.selectedGroup,
        selectedResultScannerIds.value,
        controller
      );

      return inspection;
    } catch (error) {
      showToastError(error);

      throw new Error("fetchScannersDetails");
    }
  }
  function cancelAllRequests() {
    for (const request of requests.values()) {
      request.abort();
    }
  }
  function resetState() {
    filters.value = INITIAL_SCANNER_FILTERS;
    selectedScannerType.value = ScannerType.Results;
    selectedResultScannerIds.value = [];
    isAwaitingInspectionExport.value = false;
    activeScannerIds.value = {
      [ScannerType.Results]: null,
      [ScannerType.InfoPending]: null,
      [ScannerType.AnalysisPending]: null,
    };
    exportSettings.value = null;
    scannersService.value = null;
  }

  return {
    filters,
    selectedScannerType,
    selectedResultScannerIds,
    isAwaitingInspectionExport,
    activeScannerIds,
    exportSettings,
    scannersService,
    convertedResultsScanners,
    minMaxDiameters,
    filteredResultsScanners,
    filteredResultsScannersIds,
    filteredInfoPendingScanners,
    filteredAnalysisPendingScanners,
    filteredPipeScanners,
    currentFilteredScanners,
    hasFilteredScanners,
    isAllResultsScannersSelected,
    selectedResultsScannersCount,
    activeScannerId,
    activeScanner,
    setFilters,
    initFilters,
    setSelectedScannerType,
    addSelectedResultScannerId,
    removeSelectedResultScannerId,
    clearSelectedResultScannerIds,
    setSelectedResultScannerIds,
    toggleAllResultsScanners,
    toggleResultsScannerSelection,
    setIsAwaitingInspectionExport,
    exportResultsScanners,
    setActiveScannerIds,
    toggleActiveScannerId,
    setExportSettings,
    setScannersService,
    fetchPipeScanners,
    fetchScannersDetails,
    cancelAllRequests,
    resetState,
  };
});
