import { useToast } from "vue-toast-notification";

export function showToastError(error: unknown) {
  const toast = useToast();

  let message = "";

  if (
    error instanceof Error &&
    error.name !== "CancelError" &&
    error.name !== "CanceledError"
  ) {
    message = error.message.trim();
  } else if (typeof error === "string") {
    message = error.trim();
  }

  console.error(error);
  if (message)
    toast.error(`Read Inspection Stats - ${message}`, {
      position: "top-right",
    });
}
