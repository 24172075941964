import { defineStore } from "pinia";
import { ref } from "vue";
import { i18n } from "@/config/i18n";
import { unitsConverter } from "@/config/units";
import { LOCALE_LS_KEY, UNITS_LS_KEY } from "@/constants/unitsAndLocale";
import type { Locale, Units } from "@/types/settings";

export const useSettingsStore = defineStore("settings", () => {
  const units = ref<Units>(unitsConverter.instance.current.value);

  function setUnits(newUnits: Units) {
    units.value = newUnits;
    localStorage.setItem(UNITS_LS_KEY, newUnits);
    unitsConverter.instance.current.value = newUnits;
  }

  const locale = ref<Locale>(i18n.global.locale);

  function setLocale(newLocale: Locale) {
    locale.value = newLocale;
    localStorage.setItem(LOCALE_LS_KEY, newLocale);
    i18n.global.locale = newLocale;
  }

  return {
    units,
    locale,
    setUnits,
    setLocale,
  };
});
