import { PAGES } from "@/constants/pagesNames";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

export function useSelectedPipelineIdInURL() {
  const URL_SELECTED_PIPELINE_ID = "selected_pipeline_id";

  const route = useRoute();
  const router = useRouter();

  const pipelineIdInURL = computed(() => {
    let pipelineId = route.query[URL_SELECTED_PIPELINE_ID];
    if (!pipelineId) {
      const currentUrl = new URL(window.location.href);
      const searchParams = new URLSearchParams(currentUrl.search);

      pipelineId = searchParams.get(URL_SELECTED_PIPELINE_ID);
    }

    return pipelineId;
  });

  function setPipelineIdToURL(pipelineId) {
    router.push({ query: { [URL_SELECTED_PIPELINE_ID]: pipelineId } });
  }

  function removePipelineIdFromURL(shouldRedirectToDashboard) {
    if (URL_SELECTED_PIPELINE_ID in route.query) {
      const newQuery = structuredClone(route.query);
      delete newQuery[URL_SELECTED_PIPELINE_ID];

      router.replace({
        name: shouldRedirectToDashboard ? PAGES.DASHBOARD.NAME : undefined,
        query: newQuery,
      });
    }
  }

  return {
    pipelineIdInURL,
    setPipelineIdToURL,
    removePipelineIdFromURL,
  };
}
