import { STYLE_MAP } from "@/constants/mapBox";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useMapStore = defineStore("map", () => {
  const mapStyle = ref<string>(STYLE_MAP);

  function setMapStyle(style: string) {
    mapStyle.value = style;
  }

  const isMapMode = ref(true);

  function switchMapmode() {
    isMapMode.value = !isMapMode.value;
  }

  const showMapScanners = ref(false);

  function switchMapScanners() {
    showMapScanners.value = !showMapScanners.value;
  }

  return {
    mapStyle,
    isMapMode,
    showMapScanners,
    setMapStyle,
    switchMapmode,
    switchMapScanners,
  };
});
