export const PAGES = {
  DASHBOARD: {
    PATH: "/",
    NAME: "dashboard",
  },
  ANOMALIES: {
    PATH: "/anomalies",
    NAME: "anomalies",
  },
  JOINTS: {
    PATH: "/joints",
    NAME: "joints",
  },
  PIPE_PARTS: {
    PATH: "/pipe-parts",
    NAME: "pipe-parts",
  },
  SAFETY_FACTOR: {
    PATH: "/safety-factor",
    NAME: "safety-factor",
  },
  SCANNER_CONTROL: {
    PATH: "/scanner-control",
    NAME: "scanner-control",
    CHILDREN: {
      ANALYSER: {
        PATH: ":id/analyser",
        NAME: "scanner-control-analyser",
      },
    },
  },
  SETTINGS: {
    PATH: "/settings",
    NAME: "settings",
  },
  CLIENT_ADMINISTRATION: {
    PATH: "/client-administration",
    NAME: "client-administration",
  },
  PROJECT_ADMINISTRATION: {
    PATH: "/project-administration",
    NAME: "project-administration",
  },
};
