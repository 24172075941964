import type { PipePart } from "@/types/pipeParts";

interface ReduceInitValue {
  maxValue: number | null;
  maxValueKey: string | null;
}
const reduceInitValue: ReduceInitValue = {
  maxValue: null,
  maxValueKey: null,
};

export const findMainUncertainty = (pipePart: PipePart) => {
  if (pipePart.safety_factor_calculation) {
    const keys = Object.getOwnPropertyNames(pipePart.safety_factor_calculation);
    const uncertaintyKeys = keys.filter((key) =>
      key.includes("_uncertainty_contribution")
    );

    const { maxValueKey } = uncertaintyKeys.reduce((acc, key) => {
      const currentValue = pipePart.safety_factor_calculation[key];
      if (!currentValue) return acc;

      if (acc.maxValue === null || currentValue > acc.maxValue) {
        acc.maxValue = currentValue;
        acc.maxValueKey = key;
        return acc;
      }
      return acc;
    }, reduceInitValue);

    if (maxValueKey) {
      return maxValueKey.replace("_uncertainty_contribution", "");
    }

    return "none";
  }
  return "none";
};
