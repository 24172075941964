import { getPipeSegmentIdsBetweenElements } from "@/features/dig-up/helpers";
import type { Locale } from "@/types/settings";
import type { PipePart } from "@/types/pipeParts";
import { useToast, type ToastPluginApi } from "vue-toast-notification";

type ID = number;

export class DigUpSelector {
  value: ID[];
  lang?: Locale;
  toast: ToastPluginApi;

  constructor(lang?: Locale) {
    this.value = [];
    this.lang = lang;
    this.toast = useToast();
  }

  set(featuresIds: ID[]) {
    this.value = featuresIds;
  }

  setByCtrl(id: ID) {
    if (this.value.includes(id)) {
      this.value = this.value.filter((checkedId) => checkedId !== id);
    } else {
      if (this.value.length === 3) {
        this.showLimitToast();
        this.value.shift();
      }
      this.value.push(id);
    }
  }

  setByShift(id: ID, filteredPipeParts: PipePart[]) {
    if (this.value.length !== 1) {
      this.value = [];
      this.value.push(id);
    } else {
      this.value.push(id);
      const segmentIds = getPipeSegmentIdsBetweenElements(
        filteredPipeParts,
        this.value,
        this.showLimitToast.bind(this)
      );
      this.value = segmentIds;
    }
  }

  showLimitToast() {
    const message =
      this.lang === "en"
        ? "Maximum length of digging elements reached (3)"
        : "Maximale lengte van graafelementen bereikt (3)";

    this.toast.default(message, {
      position: "top-right",
    });
  }

  has(elementId: ID) {
    return this.value.includes(elementId);
  }

  clear() {
    this.value = [];
  }

  get size() {
    return this.value.length;
  }
}
