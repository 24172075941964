type IncomingArray = number[];

export const getQuantile = (arr: IncomingArray, quantile: number) => {
  if (!arr || arr.length === 0) return 0;
  const abc = (array: IncomingArray) => array.map((x) => Math.abs(x));
  const asc = (array: IncomingArray) => array.sort((a, b) => a - b);
  const absolute = abc(arr);
  const sorted = asc(absolute);
  const pos = (sorted.length - 1) * quantile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};
