import { dayjs } from "@/config/dayjs";
import { ScannerType } from "@/pages/scanner-control/config";
import type { PipeScanners } from "@/types/pipeScanners";

import { rangeFiltering } from "@/utils/filterHelpers";

export function filterScanners(
  scanners: PipeScanners[keyof PipeScanners],
  scannerType,
  filters
) {
  const {
    search,
    inspectionDateRange: [dateFrom, dateTo],
    materials,
    diameterRange,
    constuctionYearRange: [constuctionFrom, constuctionTo],
  } = filters;

  let filteredScanners = scanners.filter(
    (scanner) =>
      dayjs(scanner?.inspection_date).isSameOrAfter(dayjs(dateFrom)) &&
      dayjs(scanner?.inspection_date).isSameOrBefore(dayjs(dateTo))
  );

  if (materials.length) {
    filteredScanners = filteredScanners.filter((scanner) =>
      materials.includes(scanner?.material)
    );
  }

  if (search.length > 2 && scannerType !== ScannerType.InfoPending) {
    const normalizedSearch = search.toLowerCase().trim();

    filteredScanners = filteredScanners.filter((scanner) => {
      let tag = "";
      let address = "";
      if ("tag" in scanner) tag = (scanner.tag as string).toLowerCase().trim();
      if ("address" in scanner)
        address = (scanner.address as string)?.toLowerCase().trim();

      return (
        tag.includes(normalizedSearch) || address?.includes(normalizedSearch)
      );
    });
  }

  if (scannerType === ScannerType.Results) {
    filteredScanners = rangeFiltering(
      filteredScanners,
      // @ts-expect-error
      "diameter",
      diameterRange
    );

    if (constuctionFrom.length === 4) {
      filteredScanners = filteredScanners.filter(
        // TODO check what is going on here
        // @ts-expect-error
        (scanner) => constuctionFrom <= scanner?.constructionyear
      );
    }

    if (constuctionTo.length === 4) {
      filteredScanners = filteredScanners.filter(
        // @ts-expect-error
        (scanner) => constuctionTo >= scanner?.constructionyear
      );
    }
  }

  return filteredScanners;
}
