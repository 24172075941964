import type { PipePartFilters } from "@/types/pipeParts";
import { i18n } from "@/config/i18n";
import { unitsConverter } from "@/config/units";
import { DigUpSelector } from "@/features/dig-up/helpers";
import {
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { getMinMaxWallThickness } from "@/pages/pipe-parts/helpers";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { auth0 } from "@/config/auth0";
import { usePipelinesStore } from "./usePipelinesStore";
import { useAuthStore } from "./useAuthStore";
import { useMapStore } from "./useMapStore";
import { useAnomaliesStore } from "./useAnomaliesStore";
import { mergeChanges } from "@/utils/objects";
import type { PipePart } from "@/types/pipeParts";
import { showToastError } from "@/utils/errors";
import type { PlotlyFilter } from "@/types/plotlyFilter";

const requests = new Map();

const INITIAL_PLOTLY_FILTER: PlotlyFilter = {
  property: "",
  range: [],
};
const INITIAL_PIPE_PART_FILTERS: PipePartFilters = {
  selectedDistance: [],
  selectedPipePartTypes: [],
  selectedMaterials: [],
  selectedOvalityMax: [],
  selectedOvalityMean: [],
  selectedDeformationHor: [],
  selectedDeformationVer: [],
  plotlyFilter: INITIAL_PLOTLY_FILTER,
};

export const usePipePartsStore = defineStore("pipeParts", () => {
  const mapStore = useMapStore();
  const pipelinesStore = usePipelinesStore();
  const authStore = useAuthStore();
  const anomaliesStore = useAnomaliesStore();

  const shouldGetNewPipeParts = ref(true);

  watch(
    () => pipelinesStore.selectedInspectionId,
    () => {
      shouldGetNewPipeParts.value = true;
    }
  );

  const pipeParts = ref<PipePart[]>([]);

  const pipePartTypes = computed(() =>
    getUniqueValues(pipeParts.value, "pipe_part_type")
  );
  const materials = computed(() =>
    getUniqueValues(pipeParts.value, "material")
  );
  const convertedPipeParts = computed(() => {
    const conversionConfig = {
      distance: "m",
      length: "m",
      horizontal_out_of_straight: "m",
      vertical_out_of_straight: "m",
      remaining_mean: "mm",
      remaining_min: "mm",
      remaining_max: "mm",
      remaining_std: "mm",
      degradated_total_mean: "mm",
      degradated_total_min: "mm",
      degradated_total_max: "mm",
      degradated_total_std: "mm",
      remaining_life_calculation: {
        road_distance: "m",
        ground_water_level: "m",
      },
      wall_thickness_original: "mm",
      diameter_class: "mm",
    };

    return pipeParts.value.map((pipePart) => {
      const converted = { ...pipePart };

      for (const key in conversionConfig) {
        const unit = conversionConfig[key];
        if (key in pipePart && pipePart[key] !== null) {
          converted[key] = unitsConverter.instance.convert(pipePart[key], unit);
        }
      }

      if (pipePart.remaining_life_calculation) {
        converted.remaining_life_calculation = {
          ...pipePart.remaining_life_calculation,
        };

        for (const key in conversionConfig.remaining_life_calculation) {
          const unit = conversionConfig.remaining_life_calculation[key];
          if (
            key in pipePart.remaining_life_calculation &&
            pipePart.remaining_life_calculation[key] !== null
          ) {
            converted.remaining_life_calculation[key] =
              unitsConverter.instance.convert(
                pipePart.remaining_life_calculation[key],
                unit
              );
          }
        }
      }

      return converted;
    });
  });
  const pipePartsWithWallThickness = computed(() => {
    return convertedPipeParts.value.map((pipePart) => {
      const {
        wall_thickness_remaining_mean,
        wall_thickness_remaining_min,
        wall_thickness_remaining_max,
        wall_thickness_remaining_std,
        wall_thickness_degradated_total_mean,
        wall_thickness_degradated_total_min,
        wall_thickness_degradated_total_max,
        wall_thickness_degradated_total_std,
      } = pipePart;

      // TODO add type for getRemainingValue
      const getRemainingValue = (thicknessArray, prop) => {
        const layerNum = wallThicknessRemaining.value[prop].layerNum;
        const fullPipe = thicknessArray[0];
        const remainingValue =
          fullPipe.length > 1 ? fullPipe[layerNum] : fullPipe[0];
        return unitsConverter.instance.convert(remainingValue, "mm");
      };

      const remaining_mean = getRemainingValue(
        wall_thickness_remaining_mean,
        "mean"
      );

      const remaining_min = getRemainingValue(
        wall_thickness_remaining_min,
        "min"
      );

      const remaining_max = getRemainingValue(
        wall_thickness_remaining_max,
        "max"
      );

      const remaining_std = getRemainingValue(
        wall_thickness_remaining_std,
        "std"
      );

      return {
        ...pipePart,
        remaining_mean,
        remaining_min,
        remaining_max,
        remaining_std,
        degradated_total_mean: wall_thickness_degradated_total_mean[0],
        degradated_total_min: wall_thickness_degradated_total_min[0],
        degradated_total_max: wall_thickness_degradated_total_max[0],
        degradated_total_std: wall_thickness_degradated_total_std[0],
      };
    });
  });
  const minMaxWallThickness = computed(() =>
    getMinMaxWallThickness(pipePartsWithWallThickness.value)
  );
  const pipePartsWithTranslatedQualityAnomaly = computed(() => {
    const influenceTypes = {
      dirt: i18n.global.t(`quality_influences.dirt`),
      gas: i18n.global.t(`quality_influences.gas`),
      misalignment: i18n.global.t(`quality_influences.misalignment`),
      unaffected: i18n.global.t(`quality_influences.unaffected`),
    };

    return pipePartsWithWallThickness.value.map((pipePart) => {
      if (!pipePart.quality_anomaly) {
        return { ...pipePart, quality_anomaly: null };
      }

      let influences = pipePart.quality_anomaly.split(", ");

      influences = influences
        .map((type) => influenceTypes[type.toLowerCase()])
        .join(", ");
      return { ...pipePart, quality_anomaly: influences };
    });
  });
  const minMaxDistance = computed(() =>
    getMinMaxValue(convertedPipeParts.value, "distance")
  );
  const minMaxOvalityMax = computed(() =>
    getMinMaxValue(convertedPipeParts.value, "ovality_max")
  );
  const minMaxOvalityMean = computed(() =>
    getMinMaxValue(convertedPipeParts.value, "ovality_mean")
  );
  const minMaxDeformationHor = computed(() =>
    getMinMaxValue(convertedPipeParts.value, "horizontal_out_of_straight")
  );
  const minMaxDeformationVer = computed(() =>
    getMinMaxValue(convertedPipeParts.value, "vertical_out_of_straight")
  );
  const minMaxRoadDistance = computed(() => {
    if (convertedPipeParts.value[0].remaining_life_calculation) {
      return getMinMaxValue(
        convertedPipeParts.value,
        "remaining_life_calculation.road_distance" as keyof PipePart
      );
    }

    return [];
  });
  const minMaxDegradationRate = computed(() => {
    if (convertedPipeParts.value[0].remaining_life_calculation) {
      return getMinMaxValue(
        convertedPipeParts.value,
        "remaining_life_calculation.degradation_rate" as keyof PipePart
      );
    }

    return [];
  });
  const minMaxGroundWaterLevel = computed(() => {
    if (convertedPipeParts.value[0].remaining_life_calculation) {
      return getMinMaxValue(
        convertedPipeParts.value,
        "remaining_life_calculation.road_distance" as keyof PipePart
      );
    }

    return [];
  });

  function setPipeParts(newPipeParts: PipePart[]) {
    pipeParts.value = newPipeParts;
  }
  async function getPipeParts() {
    try {
      if (!pipelinesStore.selectedInspectionId)
        throw new Error("No selectedInspectionId in store");

      setLoading(true);

      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();

      requests.set(
        "pipePartsRequest",
        DefaultService.readPipePartsInlineInspectionsPipePartsInspectionIdGroupGet(
          pipelinesStore.selectedInspectionId,
          authStore.selectedGroup
        )
      );

      const pipeParts = await requests.get("pipePartsRequest");
      setPipeParts(pipeParts);
    } catch (error) {
      showToastError(error);
    } finally {
      setLoading(false);
    }
  }
  async function initPipeParts() {
    await getPipeParts();
    requests.delete("pipePartsRequest");
    initFilters();
    checkWTRemainigLevels();

    shouldGetNewPipeParts.value = false;
  }

  const isLoading = ref(true);

  function setLoading(loading: boolean) {
    isLoading.value = loading;
  }

  const isAwaitingDigup = ref(false);

  function setIsAwaitingDigup(newValue: boolean) {
    isAwaitingDigup.value = newValue;
  }

  const filters = ref<PipePartFilters>(INITIAL_PIPE_PART_FILTERS);

  const hasActiveFilters = computed(() => {
    return Object.entries(filters.value).some(([key, value]) => {
      if (["selectedPipePartTypes", "selectedMaterials"].includes(key)) {
        // @ts-expect-error
        return value.length > 0;
      }

      if (key === "selectedDistance") {
        return (
          minMaxDistance.value[0] !== value[0] ||
          minMaxDistance.value[1] !== value[1]
        );
      }
    });
  });
  const filteredPipeParts = computed(() => {
    const {
      selectedDistance,
      selectedPipePartTypes,
      selectedMaterials,
      selectedOvalityMax,
      selectedOvalityMean,
      selectedDeformationHor,
      selectedDeformationVer,
      plotlyFilter,
    } = filters.value;

    let filtered = pipePartsWithTranslatedQualityAnomaly.value;

    if (selectedPipePartTypes.length) {
      filtered = valueFiltering(
        filtered,
        "pipe_part_type",
        selectedPipePartTypes
      );
    }

    if (selectedMaterials.length) {
      filtered = valueFiltering(filtered, "material", selectedMaterials);
    }

    filtered = rangeFiltering(filtered, "distance", selectedDistance);

    if (!mapStore.isMapMode) {
      filtered = rangeFiltering(filtered, "ovality_max", selectedOvalityMax);
      filtered = rangeFiltering(filtered, "ovality_mean", selectedOvalityMean);
      filtered = rangeFiltering(
        filtered,
        "horizontal_out_of_straight",
        selectedDeformationHor
      );
      filtered = rangeFiltering(
        filtered,
        "vertical_out_of_straight",
        selectedDeformationVer
      );
    }

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;
      // @ts-expect-error
      filtered = rangeFiltering(filtered, property, range);
    }

    return filtered;
  });

  function setFilters(newFilters: Partial<PipePartFilters>) {
    mergeChanges(filters.value, newFilters);
    selectedPipePartId.value = null;
  }
  function resetPlotlyFilter() {
    setFilters({ plotlyFilter: INITIAL_PLOTLY_FILTER });
  }
  function initFilters() {
    setFilters({
      selectedDistance: minMaxDistance.value,
      selectedOvalityMax: minMaxOvalityMax.value,
      selectedOvalityMean: minMaxOvalityMean.value,
      selectedDeformationHor: minMaxDeformationHor.value,
      selectedDeformationVer: minMaxDeformationVer.value,
    });
  }
  function resetFilters() {
    filters.value = INITIAL_PIPE_PART_FILTERS;
  }

  const selectedPipePartId = ref<string | null>(null);

  const selectedPipePart = computed(() => {
    if (!selectedPipePartId.value) return;

    return pipePartsWithWallThickness.value.find(
      ({ id }) => id === selectedPipePartId.value
    );
  });
  const anomaliesByPipePart = computed(() => {
    if (!selectedPipePart.value) {
      return [];
    }

    return anomaliesStore.anomalies.filter((anomaly) =>
      anomaly.pipe_parts.some(
        (pipePart) => pipePart.id === selectedPipePart.value?.id
      )
    );
  });

  function setSelectedPipePartId(newSelectedPipePartId: string) {
    selectedPipePartId.value = newSelectedPipePartId;
  }

  const selectedGradientMode = ref("degradation_level");

  function setSelectedGradientMode(newGradientMode) {
    selectedGradientMode.value = newGradientMode;
  }

  const selectedLegend = ref(null);

  function setSelectedLegend(newLegend) {
    selectedLegend.value = newLegend;
  }
  function toggleSelectedLegend(legend) {
    if (selectedLegend.value === legend) {
      setSelectedLegend(null);
      return;
    }

    setSelectedLegend(legend);
  }

  const partsSelector = ref(new DigUpSelector(i18n.global.locale));

  const showAnomalies = ref(false);

  function setShowAnomalies(areAnomaliesVisible) {
    showAnomalies.value = areAnomaliesVisible;
  }
  function toggleAnomaliesVisibility() {
    setShowAnomalies(!showAnomalies.value);
  }

  const wallThicknessRemaining = ref({
    mean: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
    min: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
    max: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
    std: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
  });

  function setWallThicknessForLevel(payload) {
    const { level, changes } = payload;
    wallThicknessRemaining.value[level] = {
      ...wallThicknessRemaining.value[level],
      ...changes,
    };
  }
  function checkWTRemainigLevels() {
    for (const level in wallThicknessRemaining.value) {
      const levelKey = `wall_thickness_remaining_${level}`;

      const pipePartWithMultipleLayers = pipeParts.value.find(
        (pipePart) =>
          pipePart.hasOwnProperty(levelKey) &&
          pipePart[levelKey][0].length > 1 &&
          pipePart.material_layers.length
      );

      const levelHasMultipleLayers = pipePartWithMultipleLayers !== undefined;
      let newLevelLength = 1;

      if (levelHasMultipleLayers) {
        newLevelLength = pipePartWithMultipleLayers[levelKey][0].length;
      }

      const levelChanges = {
        isMultipleLayers: levelHasMultipleLayers,
        length: newLevelLength,
      };

      setWallThicknessForLevel({ level, changes: levelChanges });
    }
  }

  function cancelAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  }

  return {
    shouldGetNewPipeParts,
    pipeParts,
    isLoading,
    isAwaitingDigup,
    filters,
    selectedPipePartId,
    selectedGradientMode,
    selectedLegend,
    partsSelector,
    showAnomalies,
    wallThicknessRemaining,
    pipePartTypes,
    materials,
    convertedPipeParts,
    pipePartsWithWallThickness,
    minMaxWallThickness,
    pipePartsWithTranslatedQualityAnomaly,
    minMaxDistance,
    minMaxOvalityMax,
    minMaxOvalityMean,
    minMaxDeformationHor,
    minMaxDeformationVer,
    minMaxRoadDistance,
    minMaxDegradationRate,
    minMaxGroundWaterLevel,
    selectedPipePart,
    anomaliesByPipePart,
    hasActiveFilters,
    filteredPipeParts,
    setPipeParts,
    getPipeParts,
    initPipeParts,
    setLoading,
    setIsAwaitingDigup,
    setFilters,
    resetPlotlyFilter,
    initFilters,
    resetFilters,
    setSelectedPipePartId,
    setSelectedGradientMode,
    setSelectedLegend,
    toggleSelectedLegend,
    setShowAnomalies,
    toggleAnomaliesVisibility,
    setWallThicknessForLevel,
    checkWTRemainigLevels,
    cancelAllRequests,
  };
});
