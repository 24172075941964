import { auth0 } from "@/config/auth0";
import { ScannerType } from "@/pages/scanner-control/config";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useAuthStore } from "./useAuthStore";
import {
  getScannersAnalysisPending,
  getScannersInfoPending,
  getScannersResult,
} from "@/features/pipescanner/api";
import { mergeChanges } from "@/utils/objects";
import type { PipeScanners } from "@/types/pipeScanners";
import { showToastError } from "@/utils/errors";

const requests = new Map();

const INITIAL_PIPE_SCANNERS_STATE: PipeScanners = {
  [ScannerType.Results]: [],
  [ScannerType.InfoPending]: [],
  [ScannerType.AnalysisPending]: [],
};

export const usePipeScannersStore = defineStore("pipeScanners", () => {
  const authStore = useAuthStore();

  const pipeScanners = ref(structuredClone(INITIAL_PIPE_SCANNERS_STATE));

  const mergedPipeScanners = computed(() => {
    const scannersValues = Object.values(pipeScanners.value);
    let merged: typeof scannersValues = [];

    for (const item of scannersValues) {
      merged = merged.concat(item);
    }

    return merged;
  });

  function setPipeScanners(changes: Partial<PipeScanners>) {
    mergeChanges(pipeScanners.value, changes);
  }
  function setScanners({ key, scanners }) {
    pipeScanners.value[key] = scanners;
  }
  function updateScanner({ key, scannerId, updateForm }) {
    pipeScanners.value[key] = pipeScanners.value[key].map((scanner) => {
      if (scanner.id !== scannerId) {
        return scanner;
      }
      const updatedScanner = { ...scanner };
      const fields = Object.getOwnPropertyNames(updateForm);
      fields.forEach((field) => {
        if (updatedScanner.hasOwnProperty(field)) {
          updatedScanner[field] = updateForm[field];
        }
      });

      return updatedScanner;
    });
  }
  function resetScanners() {
    pipeScanners.value = structuredClone(INITIAL_PIPE_SCANNERS_STATE);
  }

  const isLoading = ref({
    [ScannerType.Results]: false,
    [ScannerType.InfoPending]: false,
    [ScannerType.AnalysisPending]: false,
  });

  function setIsLoading(changes: Partial<typeof isLoading.value>) {
    mergeChanges(isLoading.value, changes);
  }

  // TODO: There should be a single endpoint for fetching pipe scanners
  async function fetchResultsScanners() {
    try {
      setIsLoading({ [ScannerType.Results]: true });
      setPipeScanners({ [ScannerType.Results]: [] });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("resultsScanners", controller);

      const scanners = await getScannersResult(
        token,
        authStore.selectedGroup,
        controller
      );

      setPipeScanners({ [ScannerType.Results]: scanners });
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading({ [ScannerType.Results]: false });
    }
  }
  async function fetchInfoScanners() {
    try {
      setIsLoading({ [ScannerType.InfoPending]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("infoScanners", controller);

      const scanners = await getScannersInfoPending(
        token,
        authStore.selectedGroup,
        controller
      );

      setPipeScanners({ [ScannerType.InfoPending]: scanners });
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading({ [ScannerType.InfoPending]: false });
    }
  }
  async function fetchAnalysisPendingScanners() {
    try {
      setIsLoading({ [ScannerType.AnalysisPending]: true });

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("analysisPendingScanners", controller);

      const scanners = await getScannersAnalysisPending(
        token,
        authStore.selectedGroup,
        controller
      );

      setPipeScanners({ [ScannerType.AnalysisPending]: scanners });
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading({ [ScannerType.AnalysisPending]: false });
    }
  }
  function cancelAllRequests() {
    for (const request of requests.values()) {
      request.abort();
    }
  }

  return {
    pipeScanners,
    isLoading,
    mergedPipeScanners,
    setPipeScanners,
    setScanners,
    updateScanner,
    resetScanners,
    setIsLoading,
    fetchResultsScanners,
    fetchInfoScanners,
    fetchAnalysisPendingScanners,
    cancelAllRequests,
  };
});
