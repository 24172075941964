/* eslint-disable prefer-const */
import axios from "axios";
import lookup from "geojson-geometries-lookup";
import { fromArrayBuffer } from "geotiff";
import NLD_Borders from "../NLD_10.geo.json";

export const checkCoordInNetherlands = ([lng, lat]) => {
  const search = new lookup(NLD_Borders);
  const countries = search.getContainers({
    type: "Point",
    coordinates: [lng, lat],
  });
  if (
    countries.features.length &&
    countries.features[0].properties.isoA3 === "NLD"
  ) {
    return true;
  }
  return false;
};

const removeUndefinedAndBigNumber = (arr) => {
  if (!arr.find((el) => el !== undefined && el < 10000)) return [];

  return arr.map((el, i) => {
    let j = 0;
    let num = el;
    let isBack = false;

    while (num === undefined || num > 10000) {
      if (i + j === arr.length - 1) {
        isBack = true;
        j = 0;
      }

      if (isBack) {
        j--;
      } else {
        j++;
      }

      num = arr[i + j];
    }
    return Number(num.toFixed(2));
  });
};

export const getGeoTIFF = (coordinates, controller) => {
  return new Promise((resolve, reject) => {
    if (!coordinates.length) reject("No coordinates!");
    const lenCoord = coordinates.length;

    if (
      !checkCoordInNetherlands(coordinates[0]) ||
      !checkCoordInNetherlands(coordinates[lenCoord - 1])
    ) {
      reject("Coordinates not in the Netherlands");
    }

    const allLng = coordinates.map((c) => c[0]);
    const allLat = coordinates.map((c) => c[1]);
    const minLng = Math.min(...allLng);
    const minLat = Math.min(...allLat);
    const maxLng = Math.max(...allLng);
    const maxLat = Math.max(...allLat);
    const baseUrl = "https://service.pdok.nl/rws/ahn/wcs/v1_0";
    const WIDTH = 1777;
    const HEIGHT = 1253;
    const params = {
      VERSION: "1.0.0",
      service: "wcs",
      REQUEST: "GetCoverage",
      FORMAT: "image/tiff",
      TRANSPARENT: "true",
      // coverage: "ahn3_05m_dtm",
      coverage: "dtm_05m",
      CRS: "EPSG:4326",
      // CRS: "EPSG:28992",
      WIDTH,
      HEIGHT,
      BBOX: [minLng, minLat, maxLng, maxLat].join(","),
    };

    let elevationList: any[] = [];
    let pixelCoordinates: any[] = [];

    axios
      .get(baseUrl, {
        params,
        responseType: "arraybuffer",
        signal: controller.signal,
      })
      .then(async (res) => {
        const transform = (a, b, M, roundToInt = false) => {
          const round = (v) => (roundToInt ? v | 0 : v);
          return [
            round(M[0] + M[1] * a + M[2] * b),
            round(M[3] + M[4] * a + M[5] * b),
          ];
        };

        const tiff = await fromArrayBuffer(res.data);
        const image = await tiff.getImage();

        // Construct the WGS-84 forward and inverse affine matrices:
        const { ModelPixelScale: s, ModelTiepoint: t } = image.fileDirectory;
        let [sx, sy] = s;
        let [, , , gx, gy] = t;
        sy = -sy; // WGS-84 tiles have a "flipped" y component

        const gpsToPixel = [-gx / sx, 1 / sx, 0, -gy / sy, 0, 1 / sy];

        const rasters = await image.readRasters();
        const { width, [0]: raster } = rasters;

        for (let i = 0; i < coordinates.length; i++) {
          const [lng, lat] = coordinates[i];

          const [x, y] = transform(lng, lat, gpsToPixel, true);

          const elevation = raster[x + y * width];
          elevationList.push(elevation);
          pixelCoordinates.push([x, y]);
        }

        elevationList = removeUndefinedAndBigNumber(elevationList);

        resolve(elevationList);
      })
      .catch((error) => reject(error));
  });
};

export const getDepth = (elevation, hProfile) => {
  const depth: number[] = [];
  elevation.forEach((el, i) => {
    const diff = Number((el - hProfile[i]).toFixed(2));
    if (diff < 1.5) {
      depth.push(Number((hProfile[i] - (1.5 - diff)).toFixed(2)));
    } else {
      depth.push(hProfile[i]);
    }
  });
  return depth;
};
