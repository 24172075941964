import type { Anomaly } from "@/types/anomalies";

/**
 * This function searches for all materials of anomalies and returns an array of unique materials
 */
export function getAllMaterials(anomalies: Anomaly[]) {
  const allMaterials: string[] = [];
  anomalies.forEach((an) => {
    an.pipe_parts.forEach((pp) => allMaterials.push(pp.material));
  });
  return Array.from(new Set(allMaterials));
}
