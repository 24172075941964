const getObjectValueByPath = (obj, path) => {
  return path.split(".").reduce((prev, key) => prev && prev[key], obj);
};

export const getUniqueValues = <T extends any[]>(
  arr: T,
  key: keyof T[number]
): T[number][keyof T[number]][] => {
  const allValues = arr.map((el) => el[key]);
  return Array.from(new Set(allValues));
};

export const getMinMaxValue = <T extends any[]>(
  arr: T,
  path: keyof T[number],
  round?: boolean
): [number, number] => {
  const values = arr
    .map((obj) => getObjectValueByPath(obj, path))
    .filter((el) => Number.isFinite(el));
  let min = Math.min(...values);
  let max = Math.max(...values);

  if (round) {
    min = Math.round(min * 100 - 1) / 100;
    max = Math.round(max * 100 + 1) / 100;
  }

  return [min, max];
};

export const rangeFiltering = <T extends any[]>(
  arr: T,
  path: keyof T[number],
  [min, max]: [number, number] | []
) => {
  if (
    !arr ||
    min === undefined ||
    max === undefined ||
    !isFinite(min) ||
    !isFinite(max)
  ) {
    return arr;
  }

  return arr.filter((obj) => {
    const value = getObjectValueByPath(obj, path) ?? min;
    return value >= min && value <= max;
  });
};

export const valueFiltering = (arr, path, valueArr) => {
  if (!arr) {
    return [];
  }
  if (!valueArr || valueArr.length === 0) {
    return arr;
  }

  return arr.filter((obj) => {
    const value = getObjectValueByPath(obj, path);
    return valueArr.includes(value);
  });
};

export const findAddedAndRemovedInArray = (currentArray, prevArray) => {
  const added = currentArray.find((value) => !prevArray.includes(value));
  const removed = prevArray.find((value) => !currentArray.includes(value));

  return [added, removed];
};

export const getMaxAngle = (joints, key) => {
  let [min, max]: (string | number)[] = getMinMaxValue(joints, key);
  min = min.toFixed(2);
  max = max.toFixed(2);
  const posMin = Math.abs(Number(min));
  const posMax = Math.abs(Number(max));
  return posMin > posMax ? posMin : posMax;
};
