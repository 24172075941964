import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useToast } from "vue-toast-notification";
import { auth0 } from "@/config/auth0";
import { i18n } from "@/config/i18n";
import { unitsConverter } from "@/config/units";
import { getAnomaliesMapData } from "@/features/anomaly/helpers";
import { generateDigupSheet } from "@/features/dig-up/api";
import {
  DigUpSelector,
  getDataForDigupRequest,
} from "@/features/dig-up/helpers";
import {
  getAllMaterials,
  getCenterAndConvertUnits,
} from "@/pages/anomaly/helpers";
import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";
import {
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { usePipelinesStore } from "./usePipelinesStore";
import { useAuthStore } from "./useAuthStore";
import { useMapStore } from "./useMapStore";
import { mergeChanges } from "@/utils/objects";
import type { Anomaly, AnomalyFilters, PipeMapData } from "@/types/anomalies";

const INITIAL_ANOMALY_FILTERS: AnomalyFilters = {
  selectedAnomaliesTypes: [],
  selectedDistance: [],
  selectedMaterials: [],
  selectedLength: [],
};

function getLocale() {
  return i18n.global.locale;
}

const toast = useToast();
const requests = new Map();

export const useAnomaliesStore = defineStore("anomalies", () => {
  const authStore = useAuthStore();
  const mapStore = useMapStore();
  const pipelinesStore = usePipelinesStore();

  const shouldGetNewAnomalies = ref(true);

  watch(
    () => pipelinesStore.selectedInspectionId,
    () => {
      shouldGetNewAnomalies.value = true;
    }
  );

  const anomalies = ref<Anomaly[]>([]);

  const anomaliesTypes = computed(() =>
    getUniqueValues(anomaliesWithCenter.value, "anomaly_type")
  );
  const minMaxDistance = computed(() =>
    getMinMaxValue(anomaliesWithCenter.value, "distance")
  );
  const minMaxLength = computed(() =>
    getMinMaxValue(anomaliesWithCenter.value, "length")
  );
  const filterMaterials = computed(() => getAllMaterials(anomalies.value));
  const selectedAnomaly = computed(() => {
    return anomaliesWithCenter.value.find(
      (anomaly) => anomaly.id === selectedAnomalyId.value
    );
  });
  const reportedAnomalies = computed(() =>
    anomalies.value.filter((anomaly) => anomaly.is_report_anomaly)
  );
  const anomaliesWithCenter = computed(() =>
    getCenterAndConvertUnits(anomalies.value)
  );
  function setAnomalies(anomaliesData: Anomaly[]) {
    anomalies.value = anomaliesData;
  }
  async function getAnomalies() {
    try {
      setLoadingAnomalies(true);
      setAnomalies([]);
      resetFilters();

      const accessToken = await auth0.getAccessTokenSilently();
      OpenAPI.TOKEN = accessToken;

      requests.set(
        "anomaliesRequest",
        DefaultService.readAnomaliesInlineInspectionsAnomaliesInspectionIdGroupGet(
          pipelinesStore.selectedInspectionId,
          authStore.selectedGroup
        )
      );

      const anomalies = await requests.get("anomaliesRequest");
      requests.delete("anomaliesRequest");

      setAnomalies(anomalies);
      initFilters();

      shouldGetNewAnomalies.value = false;
    } catch (error) {
      if (error instanceof Error && error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Anomalies - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setLoadingAnomalies(false);
    }
  }

  const selectedAnomalyId = ref<string | null>(null);
  function setSelectedAnomalyId(anomalyId: string | null) {
    selectedAnomalyId.value = anomalyId;
  }
  function toggleSelectedAnomaly(id: string) {
    if (selectedAnomalyId.value === id) {
      setSelectedAnomalyId(null);
    } else {
      setSelectedAnomalyId(id);
    }
  }

  const anomalyFilters = ref(INITIAL_ANOMALY_FILTERS);
  const hasTypeFilters = computed(() =>
    Boolean(anomalyFilters.value.selectedAnomaliesTypes.length)
  );
  function setFilters(filters: Partial<AnomalyFilters>) {
    mergeChanges(anomalyFilters.value, filters);
    checkedAnomaliesSelector.value.clear();
  }
  function initFilters() {
    setFilters({
      selectedDistance: minMaxDistance.value,
      selectedLength: minMaxLength.value,
    });
  }
  function resetFilters() {
    anomalyFilters.value = INITIAL_ANOMALY_FILTERS;
    checkedAnomaliesSelector.value.clear();
  }

  const isLoadingAnomalies = ref(true);
  function setLoadingAnomalies(newValue: boolean) {
    isLoadingAnomalies.value = newValue;
  }

  const isAwaitingDigup = ref(false);
  function setAwaitingDigup(isAwaiting: boolean) {
    isAwaitingDigup.value = isAwaiting;
  }
  async function getDigupSheet() {
    try {
      setAwaitingDigup(true);

      const requestData = getDataForDigupRequest(
        checkedAnomaliesSelector.value.value,
        anomaliesWithCenter.value,
        getLocale(),
        unitsConverter.instance.current.value
      );

      const accessToken = await auth0.getAccessTokenSilently();

      await generateDigupSheet(
        accessToken,
        authStore.selectedGroup,
        requestData
      );
    } catch (error) {
      if (error instanceof Error && error.name !== "CancelError") {
        console.error(error);
        toast.error(`Digup generating - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setAwaitingDigup(false);
    }
  }

  const checkedAnomaliesSelector = ref(new DigUpSelector());
  const hasDistanceFilters = computed(() => {
    const [minSelectedDistance, maxSelectedDistance] =
      anomalyFilters.value.selectedDistance;

    if (minSelectedDistance === undefined || maxSelectedDistance === undefined)
      return false;

    const [minAvailableDistance, maxAvailableDistance] = minMaxDistance.value;

    return (
      minSelectedDistance > minAvailableDistance ||
      maxSelectedDistance < maxAvailableDistance
    );
  });
  const hasLengthFilters = computed(() => {
    const [minSelectedLength, maxSelectedLength] =
      anomalyFilters.value.selectedLength;

    if (minSelectedLength === undefined || maxSelectedLength === undefined)
      return false;

    const [minAvailableLength, maxAvailableLength] = minMaxLength.value;

    return (
      minSelectedLength > minAvailableLength ||
      maxSelectedLength < maxAvailableLength
    );
  });
  const hasActiveFilters = computed(() => {
    return (
      hasTypeFilters.value || hasDistanceFilters.value || hasLengthFilters.value
    );
  });
  const filteredAnomalies = computed(() => {
    let filtered = anomaliesWithCenter.value;

    if (anomalyFilters.value.selectedAnomaliesTypes.length) {
      filtered = valueFiltering(
        filtered,
        "anomaly_type",
        anomalyFilters.value.selectedAnomaliesTypes
      );
    }

    if (anomalyFilters.value.selectedMaterials.length && mapStore.isMapMode) {
      filtered = filtered.filter((an) => {
        const arr = an.pipe_parts.filter((pp) =>
          anomalyFilters.value.selectedMaterials.includes(pp.material)
        );
        return arr.length;
      });
    }

    filtered = rangeFiltering(
      filtered,
      "distance",
      anomalyFilters.value.selectedDistance
    );

    filtered = rangeFiltering(
      filtered,
      "length",
      anomalyFilters.value.selectedLength
    );

    return filtered;
  });

  const anomalyMapData = computed(() => {
    const mapData: PipeMapData[] = [];

    const pipeMapData = getPipeMapData(pipelinesStore.selectedInspectionStats);
    mapData.push(pipeMapData);

    if (pipelinesStore.selectedInspectionStats) {
      const distancePoints = Object.entries(
        pipelinesStore.selectedInspectionStats.map_trace
      ).map(([distance, geometry]) => ({
        type: "Feature",
        properties: {
          distance: unitsConverter.instance.convert(distance, "m"),
        },
        geometry,
      }));

      const distanceDataName = "distance_points";
      mapData.push({
        id: distanceDataName,
        type: "points",
        sourceOptions: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: distancePoints,
          },
        },
        layerOptions: {
          id: `layer_${distanceDataName}`,
          source: distanceDataName,
          layout: {
            visibility: "visible",
          },
          type: "circle",
          paint: {
            "circle-opacity": 0,
          },
        },
      });
    }

    const { largeAnomaliesMapData, smallAnomaliesMapData } =
      getAnomaliesMapData(
        filteredAnomalies.value,
        anomaliesTypes.value,
        checkedAnomaliesSelector.value
      );

    mapData.push(largeAnomaliesMapData, smallAnomaliesMapData);

    return mapData;
  });

  function cancellAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  }

  return {
    shouldGetNewAnomalies,
    anomalies,
    selectedAnomalyId,
    anomalyFilters,
    isLoadingAnomalies,
    isAwaitingDigup,
    checkedAnomaliesSelector,
    anomaliesTypes,
    minMaxDistance,
    minMaxLength,
    filterMaterials,
    selectedAnomaly,
    reportedAnomalies,
    anomaliesWithCenter,
    hasTypeFilters,
    hasDistanceFilters,
    hasLengthFilters,
    hasActiveFilters,
    filteredAnomalies,
    anomalyMapData,
    setAnomalies,
    getAnomalies,
    setSelectedAnomalyId,
    toggleSelectedAnomaly,
    setFilters,
    initFilters,
    resetFilters,
    setLoadingAnomalies,
    setAwaitingDigup,
    getDigupSheet,
    cancellAllRequests,
  };
});
