import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import { PAGES } from "@/constants/pagesNames";

const {
  DASHBOARD,
  ANOMALIES,
  JOINTS,
  PIPE_PARTS,
  SAFETY_FACTOR,
  SCANNER_CONTROL,
} = PAGES;

const routes = [
  {
    path: DASHBOARD.PATH,
    name: DASHBOARD.NAME,
    beforeEnter: authGuard,
    component: () => import("@/pages/dashboard/DashboardPage.vue"),
  },
  {
    path: ANOMALIES.PATH,
    name: ANOMALIES.NAME,
    beforeEnter: authGuard,
    component: () => import("@/pages/anomaly/AnomaliesPage.vue"),
  },
  {
    path: JOINTS.PATH,
    name: JOINTS.NAME,
    beforeEnter: authGuard,
    component: () => import("@/pages/joints/JointsPage.vue"),
  },
  {
    path: PIPE_PARTS.PATH,
    name: PIPE_PARTS.NAME,
    beforeEnter: authGuard,
    component: () => import("@/pages/pipe-parts/PipePartsPage.vue"),
  },
  {
    path: SAFETY_FACTOR.PATH,
    name: SAFETY_FACTOR.NAME,
    beforeEnter: authGuard,
    component: () => import("@/pages/safety-factor/SafetyFactorPage.vue"),
  },
  {
    path: SCANNER_CONTROL.PATH,
    name: SCANNER_CONTROL.NAME,
    beforeEnter: authGuard,
    component: () => import("@/pages/scanner-control/ScannerControlPage.vue"),
  },
  {
    path: `${SCANNER_CONTROL.PATH}/${SCANNER_CONTROL.CHILDREN.ANALYSER.PATH}`,
    name: SCANNER_CONTROL.CHILDREN.ANALYSER.NAME,
    beforeEnter: authGuard,
    component: () =>
      import("@/features/analyser/components/ScannerAnalyser.vue"),
  },
  {
    path: PAGES.SETTINGS.PATH,
    name: PAGES.SETTINGS.NAME,
    beforeEnter: authGuard,
    component: () => import("../pages/settings/SettingsPage.vue"),
  },
  {
    path: PAGES.CLIENT_ADMINISTRATION.PATH,
    name: PAGES.CLIENT_ADMINISTRATION.NAME,
    beforeEnter: authGuard,
    component: () =>
      import(
        "@/features/administration/pages/client-admin/ClientAdminPage.vue"
      ),
  },
  {
    path: PAGES.PROJECT_ADMINISTRATION.PATH,
    name: PAGES.PROJECT_ADMINISTRATION.NAME,
    beforeEnter: authGuard,
    component: () =>
      import(
        "@/features/administration/pages/project-admin/ProjectAdminPage.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
